import React, {useState, useEffect} from "react";
import {Grid, Header, Icon, Message} from "semantic-ui-react";
import LimboDataProvider from "../../../Services/LimboDataProvider";
import LimboDetailsContainer from "./LimboDetailsContainer";
import LimboSessionsSelector from "./LimboSessionsSelector";

export default function LimboMasterDetailView(props) {
    const [recentSessions, setRecentSessions] = useState([]);
    const [getSessionsErrorMessage, setGetSessionsErrorMessage] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [session, setSession] = useState({});
    const [getIndividualSessionErrorMessage, setGetIndividualSessionErrorMessage] = useState("");
    const [loadingSessions, setLoadingSessions] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(function initLimbo() {
        LimboDataProvider.init({baseURL: process.env.LIMBO_URL}).then(getRecentSessions);
    }, []);

    const getRecentSessions = async () => {
        setLoadingSessions(true);
        setGetSessionsErrorMessage("");
        LimboDataProvider.getRecentSessions().then(response => {
            console.log("(LimboMasterDetailView.initLimbo) getSessions response: ", response);
            if (response.hasOwnProperty("records")) {
                setRecentSessions(response.records);
            } else if (response.hasOwnProperty("error")) {
                console.error(response.error);
                setGetSessionsErrorMessage("There was an error getting recent sessions.");
            }
        }).catch(error => {
            console.error(error);
            setGetSessionsErrorMessage("There was an error getting recent sessions.");
        }).finally(() => {
            setLoadingSessions(false);
        });
    };

    const searchClicked = searchFilter => {
        setLoading(true);
        setSessionId(searchFilter);
        setGetIndividualSessionErrorMessage("");
        if (searchFilter !== "") {
            LimboDataProvider.getSession(searchFilter).then(response => {
                console.log("LimboMasterDetailView.searchClicked response: ", response);
                if (response.hasOwnProperty("error")) {
                    console.log(response);
                    if (response.error.response.status === 404) {
                        setGetIndividualSessionErrorMessage(`The session with session ID ${searchFilter} could not be found.`);
                    } else {
                        setGetIndividualSessionErrorMessage(`There was an error retrieving session info for the session ID ${sessionId}`);
                    }
                    setSession({});
                } else {
                    setSession(response);
                }
            }).catch(error => {
                console.error("(LimboMasterDetailView.searchClicked) error encountered: ", error);
                setGetIndividualSessionErrorMessage(`There was an error retrieving session info for the session ID ${sessionId}`);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={4}>
                    <LimboSessionsSelector
                        recentSessions={recentSessions}
                        setSessionId={setSessionId}
                        searchClicked={searchClicked}
                        sessionId={sessionId}
                        loadingSessions={loadingSessions}
                        getRecentSessions={getRecentSessions}
                        getSessionsErrorMessage={getSessionsErrorMessage}
                    />
                </Grid.Column>
                <Grid.Column width={12} textAlign="center">
                    {
                        loading === true ?
                            <Message icon>
                                <Message.Content>
                                    <Icon name="spinner" loading={true} size="huge" />
                                    The session data for {sessionId} is currently being loaded.
                                </Message.Content>
                            </Message> :
                        getIndividualSessionErrorMessage.length > 0 ?
                            <Message icon negative>
                                <Message.Content>
                                    <Icon name="exclamation circle" size="huge" />
                                    {getIndividualSessionErrorMessage}
                                </Message.Content>
                            </Message> :
                        Object.keys(session).length < 1 ? "" :
                            <LimboDetailsContainer
                                sessionId={sessionId}
                                session={session}
                            />
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
