import React, {useState, useEffect} from "react";
import {Container, Divider, Icon, Tab, Table} from "semantic-ui-react";

const DATE_DISPLAY_OPTIONS = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric", fractionalSecondDigits: 3};

export default function LimboDetailsContainer(props) {
    const [platform, setPlatform] = useState("");
    const [userAgent, setUserAgent] = useState("");
    const [proxy, setProxy] = useState("");
    const [country, setCountry] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [lowestSpeed, setLowestSpeed] = useState("");
    const [highestSpeed, setHighestSpeed] = useState("");
    const [endpoints, setEndpoints] = useState([]);
    const [comments, setComments] = useState("");
    const [userIssueTimestamp, setUserIssueTimestamp] = useState(0);
    const [userIssue, setUserIssue] = useState("");
    const [environmentalChecks, setEnvironmentalChecks] = useState([]);
    const [authenticationChecks, setAuthenticationChecks] = useState([]);

    useEffect(function setStateFromSessionData() {
        console.log("LimboMasterDetailView.searchClicked response: ", props.session);

        if (props.session) {
            setIpAddress(props.session.ip_address || "N/A");
            setUserAgent(props.session.user_agent || "N/A");
            setComments(props.session.comments);

            const environmentalCheckEvents = [];
            const authenticationCheckEvents = [];

            if (Array.isArray(props.session.events)) {
                for (const event of props.session.events) {
                    if (event.eventType === "environmental-checks") {
                        environmentalCheckEvents.push(event);
                        if (event.label === "GEO") {
                            setProxy(event.proxy || "N/A");
                            setCountry(event.country || "N/A");
                        }
                    } else if (event.eventType === "authentication-checks") {
                        authenticationCheckEvents.push(event);
                    } else if (event.eventType === "selectedDevice") {
                        setLowestSpeed(event.lowSpeed.toString() || "???");
                        setHighestSpeed(event.highSpeed.toString() || "???");
                        setPlatform(event.title);
                        setEndpoints(event.endpoints);
                    } else if (event.eventType === "user-issue") {
                        setUserIssue(event.userIssue);
                        setUserIssueTimestamp(event.timestamp);
                    }
                }
            }

            setEnvironmentalChecks(environmentalCheckEvents.sort((a, b) => a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0));
            setAuthenticationChecks(authenticationCheckEvents.sort((a, b) => a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0));
        }
    }, [props.session]);

    return (
        <Container style={{maxHeight: 710, minHeight: 710, overflowY: "auto"}}>
            <Container fluid>
                <h3>Session Information for {props.sessionId}</h3>
            </Container>
            <Divider section />
            <Tab
                panes={[
                    {
                        menuItem: {
                            key: "general-data-tab",
                            content: <label>General Data</label>
                        },
                        render: () =>
                            <Container>
                                <Table>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right">Platform</Table.HeaderCell>
                                            <Table.Cell>{platform || "The platform could not be determined."}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right">User Agent</Table.HeaderCell>
                                            <Table.Cell>{userAgent || "The user agent could not be determined."}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right">Proxy</Table.HeaderCell>
                                            <Table.Cell>{proxy || "The proxy could not be determined."}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right">Location</Table.HeaderCell>
                                            <Table.Cell>{country || "The location could not be determined."}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right">IP Address</Table.HeaderCell>
                                            <Table.Cell>{ipAddress || "The IP address could not be determined."}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right">Low Speed</Table.HeaderCell>
                                            <Table.Cell>{lowestSpeed ? `${lowestSpeed} kbps` : "The lowest speed could not be determined."}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="right">Highest Speed</Table.HeaderCell>
                                            <Table.Cell>{highestSpeed ? `${highestSpeed} kbps` : "The highest speed could not be determined."}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Table>
                                    <Table.Header fullWidth>
                                        <Table.HeaderCell colSpan={2}>Endpoints</Table.HeaderCell>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            endpoints.map(endpoint =>
                                                <Table.Row key={endpoint.id}>
                                                    <Table.Cell>{endpoint.label}</Table.Cell>
                                                    <Table.Cell>{endpoint.uri}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </Container>
                    },
                    {
                        menuItem: {
                            key: "user-issue-tab",
                            content: <label>User Issue</label>
                        },
                        render: () =>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign="right">User Issue Timestamp</Table.HeaderCell>
                                        <Table.Cell>{new Date(userIssueTimestamp).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign="right">User Issue</Table.HeaderCell>
                                        <Table.Cell>{userIssue || "The issue could not be determined."}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                    },
                    {
                        menuItem: {
                            key: "environment-checks-tab",
                            content: <label>Environmental Checks</label>
                        },
                        render: () =>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Timestamp</Table.HeaderCell>
                                        <Table.HeaderCell>Test</Table.HeaderCell>
                                        <Table.HeaderCell>Data</Table.HeaderCell>
                                        <Table.HeaderCell>Result</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        environmentalChecks.map(check =>
                                            <Table.Row key={check.label}>
                                                <Table.Cell>{new Date(check.timestamp).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</Table.Cell>
                                                <Table.Cell>{check.label}</Table.Cell>
                                                <Table.Cell><pre>{JSON.stringify(check.data, null, 4)}</pre></Table.Cell>
                                                <Table.Cell><Icon name={check.status === "Success" ? "check" : "x"} color={check.status === "Success" ? "green" : "red"} /></Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                    },
                    {
                        menuItem: {
                            key: "authentication-checks-tab",
                            content: <label>Authentication Checks</label>
                        },
                        render: () =>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Timestamp</Table.HeaderCell>
                                        <Table.HeaderCell>Test</Table.HeaderCell>
                                        <Table.HeaderCell>Data</Table.HeaderCell>
                                        <Table.HeaderCell>Result</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        authenticationChecks.map(check =>
                                            <Table.Row>
                                                <Table.Cell>{new Date(check.timestamp).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</Table.Cell>
                                                <Table.Cell>{check.label}</Table.Cell>
                                                <Table.Cell><pre>{JSON.stringify(check.data, null, 4)}</pre></Table.Cell>
                                                <Table.Cell><Icon name={check.status === "Success" ? "check" : "x"} color={check.status === "Success" ? "green" : "red"} /></Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                    }
                ]}
            />
        </Container>
    )
};
