import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, TextArea, Input, Form, Dropdown, Message} from "semantic-ui-react";
import KnoxDataProvider from "../../../Services/KnoxDataProvider";
import {v4} from "uuid";
import ContingentButton from "../../ContingentButton";

const KTY_OPTIONS = [
    {key: "RSA", text: "RSA", value: "RSA"},
    {key: "EC", text: "EC", value: "EC"}
];
const ALG_OPTIONS = [
    {key: "RS256", text: "RS256", value: "RS256"},
    {key: "RS512", text: "RS512", value: "RS512"},
    {key: "ES256", text: "ES256", value: "ES256"},
    {key: "ES512", text: "ES512", value: "ES512"}
];

const DEFAULT_KTY = "RSA";
const DEFAULT_ALG = "RS512";

export default class KnoxPublicKeyImportTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kid: v4(),
            kty: DEFAULT_KTY,
            alg: DEFAULT_ALG,
            aud: "",
            team:"",
            iss: "",
            publicKey: "",
            successMessage: ""
        }

        this.updatePublicKey = this.updatePublicKey.bind(this);
        this.importPublicKey = this.importPublicKey.bind(this);
    }

    updatePublicKey = (event, {value}) => this.setState({publicKey: value});
    updateKID = (event, {value}) => this.setState({kid: value});
    updateKTY = (event, {value}) => this.setState({kty: value});
    updateAlg = (event, {value}) => this.setState({alg: value});
    updateAud = (event, {value}) => this.setState({aud: value});
    updateTeam = (event, {value}) => this.setState({team: value});
    updateIss = (event, {value}) => this.setState({iss: value});

    importPublicKey = () => {
        this.setState({successMessage: ""}, () => {
            const encodedKey = btoa(this.state.publicKey);
            const {kid, aud, iss, alg, kty, team, publicKey} = this.state;

            if (kid && aud && iss && iss && alg && kty && publicKey && team) {
                KnoxDataProvider.import(this.state.kid, encodedKey, this.state.alg, this.state.kty, this.state.iss, this.state.aud, this.state.team).then(response => {
                    console.log(response);
                    this.setState({kid: "", aud: "", iss: "", kty: DEFAULT_KTY, alg: DEFAULT_ALG, publicKey: "", team:"", successMessage: `The ${alg} public key was successfully imported with the key ID ${kid}, audience ${aud}, and issuer ${iss}.`});
                }).catch(error => {
                    this.props.toast("Error", "Could not import public key", "error");
                });
            }
        });
    }

    render() {
        return (
             <Grid>
                 <Grid.Row>
                     <Grid.Column>
                         {
                             this.state.successMessage ?
                                 <Message attached positive width={16}>
                                     <Message.Header>Key Successfully Imported</Message.Header>
                                     <Message.Content>{this.state.successMessage}</Message.Content>
                                 </Message> : ""
                         }
                     </Grid.Column>
                 </Grid.Row>
                 <Grid.Column width={8}>
                     <Form>
                         <Form.Field
                             required
                             control={Input}
                             label="Key ID"
                             value={this.state.kid}
                             onChange={this.updateKID}
                         />
                         <Form.Field
                             required
                             control={Input}
                             label="Audience"
                             value={this.state.aud}
                             onChange={this.updateAud}
                         />
                         <Form.Field
                             required
                             label="Team"
                             control={Dropdown}
                             options={this.props.teamOptions}
                             selection
                             search
                             value={this.state.team}
                             onChange={this.updateTeam}
                         />
                         <Form.Field
                             required
                             label="Issuer"
                             control={Input}
                             value={this.state.iss}
                             onChange={this.updateIss}
                         />
                         <Form.Field
                             required
                             control={Dropdown}
                             label="Key Type"
                             value={this.state.kty}
                             onChange={this.updateKTY}
                             options={KTY_OPTIONS}
                         />
                         <Form.Field
                             required
                             control={Dropdown}
                             label="Algorithm"
                             value={this.state.alg}
                             onChange={this.updateAlg}
                             options={ALG_OPTIONS}
                         />
                         <Form.Field>
                             <label>&nbsp;</label>
                             <ContingentButton
                                 service={this.props.service}
                                 module={this.props.module}
                                 scope={this.state.team}
                                 allPermissions={this.props.permissions}
                                 user={this.props.user}
                                 content="Import Public Key"
                                 primary
                                 onClick={this.importPublicKey}
                             />
                         </Form.Field>
                     </Form>
                 </Grid.Column>
                 <Grid.Column width={8}>
                     <Form>
                         <Form.Field
                             required
                             control={TextArea}
                             label="Public Key"
                             value={this.state.publicKey}
                             onChange={this.updatePublicKey}
                             rows={15}
                         />
                     </Form>
                 </Grid.Column>
             </Grid>
        );
    }
}

KnoxPublicKeyImportTab.propTypes = {
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
};
