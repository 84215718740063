import React, {Component} from "react";
import {Header, Grid, Form, Input, TextArea, Button, Dropdown, Container, Message, Icon} from "semantic-ui-react";
import KnoxDataProvider from "../../../Services/KnoxDataProvider";
import {v4} from "uuid";

const HASH_OPTIONS = [{key: "256", text: "256", value: "256"}, {key: "512", text: "512", value: "512"}]
const ALG_OPTIONS = [{key: "EC", text: "EC", value: "EC"}, {key: "RSA", text: "RSA", value: "RSA"}]

export default class KnoxKeyPairGeneratorTab extends Component {
    constructor(props) {
        super(props);

        this.updateKID = this.updateKID.bind(this);
        this.updateAud = this.updateAud.bind(this);
        this.updateIss = this.updateIss.bind(this);
        this.generateKeyPair = this.generateKeyPair.bind(this);
        this.copyPrivateKeyClicked = this.copyPrivateKeyClicked.bind(this);
        this.copyPublicKeyClicked = this.copyPublicKeyClicked.bind(this);

        this.state = {
            kid: v4(),
            aud: "",
            iss: "",
            team:"",
            privateKey: "",
            publicKey: "",
            keyType: "",
            hashType:"",
        };
    }

    updateKID = (event, {value}) => this.setState({kid: value});
    updateAud = (event, {value}) => this.setState({aud: value});
    updateIss = (event, {value}) => this.setState({iss: value});
    updateTeam = (event, {value}) => this.setState({team: value});
    updateKeyType = (event, {value}) => this.setState({keyType: value});
    updateHashType = (event, {value}) => this.setState({hashType: value});


    copyPrivateJWKClicked = () => {
        navigator.clipboard.writeText(this.state.privateKey);
    };

    copyPrivateKeyClicked = () => {
        navigator.clipboard.writeText(this.state.privatePEM);
    };

    copyPublicJWKClicked = () => {
        navigator.clipboard.writeText(this.state.publicKey);
    };

    copyPublicKeyClicked = () => {
        navigator.clipboard.writeText(this.state.publicPEM);
    };

    generateKeyPair = () => {
        if (this.state.kid && this.state.aud && this.state.iss && this.state.keyType && this.state.hashType && this.state.team) {
            KnoxDataProvider.generate(this.state.kid, this.state.iss, this.state.aud, this.state.keyType, this.state.hashType, this.state.team).then(response => {
                console.log(response);
                this.setState({
                    privateKey: JSON.stringify(response.privateJWK, null, 4),
                    publicKey: JSON.stringify(response.publicJWK, null, 4),
                    privatePEM: response.privatePEM,
                    publicPEM: response.publicPEM,
                });
            }).catch(error => {
                console.error("(KnoxKeyPairGeneratorTab.generateKeyPair) error", error);
                this.props.toast("Error", `Error encountered generating key pair: ${error.toString()}`, "error");
            });
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Column width={16}>
                    <Grid.Row>
                        <Header>Knox Key Pair Generator</Header>
                    </Grid.Row>
                    <br />
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field
                                required
                                label="Key ID"
                                control={Input}
                                value={this.state.kid}
                                onChange={this.updateKID}
                            />
                            <Form.Field
                                required
                                label="Team"
                                control={Dropdown}
                                options={this.props.teamOptions}
                                selection
                                search
                                value={this.state.team}
                                onChange={this.updateTeam}
                            />
                            <Form.Field
                                required
                                label="Key Type"
                                control={Dropdown}
                                options={ALG_OPTIONS}
                                selection
                                value={this.state.keyType}
                                onChange={this.updateKeyType}
                            />
                            <Form.Field
                                required
                                label="Hashing Strength"
                                control={Dropdown}
                                options={HASH_OPTIONS}
                                selection
                                value={this.state.hashType}
                                onChange={this.updateHashType}
                            />
                            <Form.Field
                                required
                                label="Audience"
                                control={Input}
                                value={this.state.aud}
                                onChange={this.updateAud}
                            />
                            <Form.Field
                                required
                                label="Issuer"
                                control={Input}
                                value={this.state.iss}
                                onChange={this.updateIss}
                            />
                            <Form.Field
                                label="&nbsp;"
                                control={Button}
                                primary
                                content="Generate Key Pair"
                                onClick={this.generateKeyPair}
                            />
                        </Form.Group>
                        {
                            this.state.privateKey && this.state.publicKey ?
                                <Container fluid>
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            control={Container}
                                            text
                                            label="&nbsp;"
                                            content={
                                                <Message color="red">
                                                    <Message.Content>
                                                        <Container>
                                                            <Button onClick={this.copyPrivateJWKClicked}><Icon name="copy"/>Copy Private JWK</Button>
                                                            <Button onClick={this.copyPrivateKeyClicked}><Icon name="copy"/>Copy Private Key</Button>
                                                            <p>Copy your private key now, because it is not stored by Knox and cannot be retrieved.</p>
                                                        </Container>
                                                    </Message.Content>
                                                </Message>
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Field
                                        control={Container}
                                        text
                                        label="&nbsp;"
                                        content={
                                            <Message color="blue">
                                                <Message.Content>
                                                    <Container>
                                                        <Button onClick={this.copyPublicJWKClicked}><Icon name="copy"/>Copy Public JWK</Button>
                                                        <Button onClick={this.copyPublicKeyClicked}><Icon name="copy"/>Copy Public Key</Button>
                                                    </Container>
                                                </Message.Content>
                                            </Message>
                                        }
                                    />
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Private Key"
                                            control={TextArea}
                                            readOnly
                                            rows={20}
                                            value={this.state.privateKey}
                                        />
                                        <Form.Field
                                            label="Public Key"
                                            control={TextArea}
                                            readOnly
                                            rows={20}
                                            value={this.state.publicKey}
                                        />
                                        <Form.Field
                                            label="Private Key"
                                            control={TextArea}
                                            readOnly
                                            rows={20}
                                            value={this.state.privatePEM}
                                        />
                                        <Form.Field
                                            label="Public Key"
                                            control={TextArea}
                                            readOnly
                                            rows={20}
                                            value={this.state.publicPEM}
                                        />
                                    </Form.Group>
                                </Container> : ""
                        }
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}
