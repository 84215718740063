import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, Header, TextArea, Form, Input, Checkbox, Button, Container, Message, Icon, Confirm} from "semantic-ui-react";
import KnoxDataProvider from "../../../Services/KnoxDataProvider";
import ReactTable from "react-table-v6";
import ContingentButton from "../../ContingentButton";


export default class KnoxKeyserverSearchTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKey: "",
            showDeleted: false,
            showDeactivated: false,
            data: [],
            kid: "",
            aud: "",
            team: "",
            iss: "",
            active: true,
            deletedAt: null,
            jwk: {},
            openConfirm: false,
            userCanUpdate: false
        };

        KnoxDataProvider.init(process.env.KNOX_URL);

        this.refreshKeys = this.refreshKeys.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    componentDidMount() {
        this.refreshKeys();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevState.team) !== JSON.stringify(this.state.team)) {
            console.log("KnoxKeyserverSearchTab.componentDidUpdate", this.props.permissions);
            let updatedUserCanUpdate = false;
            for (const permission of this.props.permissions) {
                const userHasAspenPower = permission.urn === "urn:all:aspen-power";
                const serviceMatches = permission.service === this.props.service;
                const moduleMatches = permission.module === this.props.module;
                const roleMatches = permission.role === "editor";
                const scopeMatches = ["all", this.state.team].includes(permission.scope);
                if (userHasAspenPower || (serviceMatches && moduleMatches && scopeMatches && roleMatches)) {
                    updatedUserCanUpdate = true;
                    break;
                }

                console.log("KnoxKeyserverSearchTab.componentDidUpdate: aspen power? ", "user can update?", updatedUserCanUpdate, userHasAspenPower, "service matches? ", serviceMatches, "module matches? ", moduleMatches, "scope matches? ", scopeMatches, "role matches? ", roleMatches);
            }
            this.setState({userCanUpdate: updatedUserCanUpdate});
        }
    }

    refreshKeys = () => {
        KnoxDataProvider.getAll(this.state.showDeleted, this.state.showDeactivated).then(response => {
            let data = response.keys;

            this.setState({data});
        }).catch(error => {
            this.setState({data: []}, () => {
                console.error("(KnoxKeyserverSearchTab.refreshKeys) error: ", error);
                this.props.toast("Error", "There was an error getting keys from Knox", "error");
            });
        });
    };

    showConfirm = () => this.setState({openConfirm: true});

    confirmDelete = () => {
        KnoxDataProvider.delete(this.state.selectedKey).then(() => {
            this.setState({openConfirm: false});
            this.refreshKeys();
            this.props.toast("Success", "The selected key has been deleted", "success");
        }).catch(error => {
            this.props.toast("Error", "The selected key could not be deleted", "error");
            console.error("(KnoxKeyserverSearchTab.confirmDelete) error: ", error);
        });
    }

    cancelDelete = () => this.setState({openConfirm: false});

    toggleActive = () => {
        if (this.state.userCanUpdate) {
            if (this.state.active) {
                KnoxDataProvider.deactivate(this.state.selectedKey).then(response => {
                    console.log("(KnoxKeyserverSearchTab.toggleActive) deactivation response: ", response);
                    this.setState({active: false}, () => {
                        this.props.toast("Success", "The selected key is no longer active", "success");
                        this.refreshKeys();
                    });
                }).catch(error => {
                    this.props.toast("Error", "Could not deactivate the selected key", "error");
                    console.error("KnoxKeyserverSearchTab.toggleActive: could not deactivate the selected key.", error);
                });
            } else {
                KnoxDataProvider.reactivate(this.state.selectedKey).then(response => {
                    this.setState({active: true}, () => {
                        console.log("(KnoxKeyserverSearchTab.toggleActive) reactivation response: ", response);
                        this.props.toast("Success", "The selected key is now active", "success");
                        this.refreshKeys();
                    });
                }).catch(error => {
                    this.props.toast("Error", "Could not reactivate the selected key", "error");
                    console.error("KnoxKeyserverSearchTab.toggleActive: could not reactivate the selected key.", error);
                });
            }
        }
    };

    toggleShowDeleted = () => {
        this.setState({showDeleted: !this.state.showDeleted}, () => {
            this.refreshKeys();
        });
    }

    render() {
        let that = this;
        return (
            <Grid>
                <Grid.Column width={9} className="masterContainer">
                    <Grid.Column floated='left' width={8}>
                        <Container>
                            <Header as='h1' floated="left">Knox Keyserver</Header>
                            <Button.Group floated="right">
                                <Button onClick={this.toggleShowDeleted} primary={this.state.showDeleted}>Show Deleted</Button>
                                <Button onClick={that.refreshKeys}>Refresh</Button>
                            </Button.Group>
                        </Container>
                        <br />
                        <br />
                        <br />
                        <ReactTable
                            columns={[
                                {accessor: "_id", show: false},
                                {accessor: "kid", Header: "Key ID"},
                                {accessor: "team", Header: "Team"},
                                {accessor: "iss", Header: "Issuer"},
                                {accessor: "aud", Header: "Audience"},
                                {accessor: "jwk", show: false},
                                {accessor: "deletedAt", show: false},
                                {
                                    accessor: "iat",
                                    Header: "Creation Time",
                                    Cell: cell => <span>{new Date(parseInt(cell.value) * 1000).toLocaleString('EN-US')}</span>
                                }, {
                                    accessor: "active",
                                    Header: "Active",
                                    Cell: cell => cell.value.toString()
                                }
                            ]}
                            data={that.state.data}
                            filterable
                            defaultFilterMethod={(filter, rows) => {
                                if(rows[filter.id] !== null && rows[filter.id] !== undefined)
                                    return rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                            }}
                            defaultSorted={[
                                {
                                    id: 'team',
                                    desc: true
                                },
                                {
                                    id: 'iss',
                                    desc: true
                                },
                                {
                                    id: 'aud',
                                    desc: true
                                },
                                {
                                    id: 'kid',
                                    desc: true
                                },
                            ]}
                            getTrProps={(state, rowInfo) => {
                                return {
                                    onClick() {
                                        console.log("(KnoxKeyserverSearchTab.render.onClick) Row selected: ", rowInfo.row);
                                        that.setState({
                                            selectedKey: rowInfo.row._id,
                                            kid: rowInfo.row.kid,
                                            iss: rowInfo.row.iss,
                                            aud: rowInfo.row.aud,
                                            team: rowInfo.row.team,
                                            jwk: JSON.parse(new Buffer(rowInfo.row.jwk, "base64").toString("utf-8")),
                                            deletedAt: rowInfo.row.deletedAt,
                                            active: rowInfo.row.active
                                        });
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.row && that.state.selectedKey === rowInfo.row._id ? 'rgba(65, 83, 175, .5)' : '',
                                    }
                                }
                            }}
                        />
                    </Grid.Column>
                </Grid.Column>
                <Grid.Column width={7} className="detailsContainer">
                    {this.state.selectedKey?
                        <Form>
                            <Message color="yellow" icon hidden={!this.state.deletedAt}>
                                <Icon name="exclamation triangle"/>
                                <Message.Content>This key was deleted on {new Date(parseInt(this.state.deletedAt) * 1000).toLocaleString('EN-US')}.</Message.Content>
                            </Message>
                            <Form.Field
                                label="Key ID"
                                control={Input}
                                readOnly
                                value={this.state.kid}
                            />
                            <Form.Field
                                label="Issuer"
                                control={Input}
                                readOnly
                                value={this.state.iss}
                            />

                            <Form.Field
                                label="Team"
                                control={Input}
                                readOnly
                                value={this.state.team}
                            />
                            <Form.Field
                                label="Audience"
                                control={Input}
                                readOnly
                                value={this.state.aud}
                            />
                            <Form.Field
                                label="Public Key JWK"
                                control={TextArea}
                                readOnly
                                value={JSON.stringify(this.state.jwk, null, 4)}
                                rows={20}
                            />
                            {
                                !this.state.deletedAt ?
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Active"
                                            control={Checkbox}
                                            toggle
                                            checked={this.state.active.toString().toLowerCase() === "true"}
                                            onChange={this.toggleActive}
                                            floated="left"
                                        />
                                        <Form.Field>
                                            <ContingentButton
                                                service={this.props.service}
                                                module={this.props.module}
                                                scope={this.state.team}
                                                allPermissions={this.props.permissions}
                                                user={this.props.user}
                                                onClick={this.showConfirm}
                                                floated="right"
                                                content="Delete"
                                                color="red"
                                            />
                                        </Form.Field>
                                        <Confirm
                                            open={this.state.openConfirm}
                                            content={`You are about to delete the key with key ID ${this.state.kid}, issuer ${this.state.iss}, team ${this.state.team},  and audience ${this.state.aud}. This cannot be reversed. Do you want to continue?`}
                                            onCancel={this.cancelDelete}
                                            onConfirm={this.confirmDelete}
                                        />
                                    </Form.Group>
                                     : ""
                            }
                        </Form> : ""
                    }
                    <Form>

                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

KnoxKeyserverSearchTab.propTypes = {
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired
};
