import React, {Component} from "react";
import PropTypes from "prop-types";
import {Tab} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import KnoxPublicKeyImportTab from "./KnoxPublicKeyImportTab";
import KnoxKeyPairGeneratorTab from "./KnoxKeyPairGeneratorTab";
import KnoxKeyserverSearchTab from "./KnoxKeyserverSearchTab";
import UserDataProvider from "../../../Services/UserDataProvider";
import AuthDataProvider from "../../../Services/AuthDataProvider";

UserDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL});

export default class KnoxMasterDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userCanViewAllTeams: false,
            teamOptions: []
        }
    }

    componentDidMount() {
        new Promise(resolve => {
            for (const permission of this.props.userPermissions) {
                if (permission.urn === "urn:all:aspen-power" || permission.service === this.props.service && permission.module === this.props.module && permission.scope === "all" && permission.role === "editor") {
                    resolve(true);
                }
            }
            resolve(false);
        }).then(async userCanSeeAllPermissions => {
            const updatedTeams = new Set();
            if (userCanSeeAllPermissions) {
                const allPermissions = await AuthDataProvider.getAllAdminPermissions();
                for (const permission of allPermissions) {
                    updatedTeams.add(permission.scope);
                }
            } else {
                for (const permission of this.props.userPermissions) {
                    if (permission.service === this.props.service && permission.module === this.props.module && permission.role === "editor") {
                        updatedTeams.add(permission.scope);
                    }
                }
            }
            return Array.from(updatedTeams);
        }).then(updatedTeams => {
            const updatedTeamOptions = [];

            for (const team of updatedTeams.sort()) {
                updatedTeamOptions.push({key: team, text: team, value: team});
            }

            console.log("KnoxMasterDetailView.componentDidMount: ", updatedTeamOptions);
            this.setState({teamOptions: updatedTeamOptions});
        });
    }

    render() {
        return (
            <Tab
                panes={[
                    {
                        menuItem: {
                            key: "public-key-selector-tab",
                            content: <label>Public Key Selector</label>
                        },
                        render: () =>
                            <SecureTabPane
                                user={this.props.user}
                                authenticated={this.props.authenticated}
                                checkIfAuthorized={this.props.checkIfAuthorized}
                                permissionsRequired={["urn:guest:guest"]}
                                component={
                                    <KnoxKeyserverSearchTab
                                        toast={this.props.toast}
                                        service={this.props.service}
                                        module={this.props.module}
                                        permissions={this.props.permissions}
                                        user={this.props.user}
                                    />}
                                module={this.props.module}
                                permissions={this.props.permissions}
                                service={this.props.service}
                                userPermissions={this.props.userPermissions}
                                loadingPermissions={this.props.loadingPermissions}
                            />
                    },
                    {
                        menuItem: {
                            key: "key-pair-generator-tab",
                            content: <label>Key Pair Generator</label>
                        },
                        render: () =>
                            <SecureTabPane
                                user={this.props.user}
                                authenticated={this.props.authenticated}
                                checkIfAuthorized={this.props.checkIfAuthorized}
                                permissionsRequired={["urn:guest:guest"]}
                                component={
                                    <KnoxKeyPairGeneratorTab
                                        toast={this.props.toast}
                                        teamOptions={this.state.teamOptions}
                                    />
                                }
                                module={this.props.module}
                                permissions={this.props.permissions}
                                service={this.props.service}
                                role="editor"
                                userPermissions={this.props.userPermissions}
                                loadingPermissions={this.props.loadingPermissions}
                            />
                    },
                    {
                        menuItem: {
                            key: "public-key-import-tab",
                            content: <label>Import Existing Public Key</label>
                        },
                        render: () =>
                            <SecureTabPane
                                user={this.props.user}
                                authenticated={this.props.authenticated}
                                checkIfAuthorized={this.props.checkIfAuthorized}
                                permissionsRequired={["urn:guest:guest"]}
                                component={
                                    <KnoxPublicKeyImportTab
                                        toast={this.props.toast}
                                        teamOptions={this.state.teamOptions}
                                        service={this.props.service}
                                        module={this.props.module}
                                        userPermissions={this.props.userPermissions}
                                        permissions={this.props.permissions}
                                        user={this.props.user}
                                    />
                                }
                                module={this.props.module}
                                permissions={this.props.permissions}
                                service={this.props.service}
                                role="editor"
                                userPermissions={this.props.userPermissions}
                                loadingPermissions={this.props.loadingPermissions}
                            />
                    }
                ]}
            />
        );
    }
}

KnoxMasterDetailView.propTypes = {
    user: PropTypes.object.isRequired,
    toast: PropTypes.func.isRequired,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired,
    loadingPermissions: PropTypes.bool.isRequired,
    authenticated: PropTypes.bool.isRequired,
    checkIfAuthorized: PropTypes.func.isRequired
};
