import React, {useState, useEffect} from "react";
import {Button, Card, Container, Form, Grid, Icon, List, Message, Search, Select} from "semantic-ui-react";

const BRANDS = [
    {key: "all", text: "All Brands", value: "ALL"},
    {key: "mml", text: "MML", value: "mml"}
];

export default function LimboSessionsSelector(props) {
    const [searchResults, setSearchResults] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("ALL");

    const handleSearchChange = (event, {value}) => {
        setSearchFilter(value);
        setSearchResults(props.recentSessions.filter(session => session.id.includes(searchFilter)));
    };

    const onResultSelect = sessionId => {
        props.setSessionId(sessionId);
    };

    useEffect(function updateSearchResults() {
        setSearchResults(props.recentSessions.filter(session => session.id.includes(searchFilter)));
    }, [JSON.stringify(props.recentSessions), searchFilter]);

    return (
        <Grid>
            <Grid.Column width={16}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label>Session ID</label>
                                    <Search
                                        results={searchResults}
                                        onResultSelect={onResultSelect}
                                        onSearchChange={handleSearchChange}
                                        value={searchFilter}
                                        open={false}
                                        fluid
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button disabled={!searchFilter} primary onClick={() => props.searchClicked(searchFilter)} fluid>Search</Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Container style={{maxHeight: 620, minHeight: 620, overflowY: "auto"}}>
                            {
                                props.loadingSessions ?
                                    <Message icon color="yellow">
                                        <Icon name="spinner" loading size="huge" />
                                        <Message.Content>
                                            Loading recent sessions...
                                        </Message.Content>
                                    </Message> :
                                props.getSessionsErrorMessage.length > 0 ?
                                    <Message icon negative>
                                        <Icon name="exclamation circle" size="huge" />
                                        <Message.Content>
                                            {props.getSessionsErrorMessage}
                                        </Message.Content>
                                    </Message> :
                                searchResults.length < 1 ?
                                    <Card fluid>
                                        <Card.Content>
                                            <Card.Description>
                                                <h5>No Sessions</h5>
                                                {
                                                    !Array.isArray(props.recentSessions) || props.recentSessions.length < 1 ?
                                                        "There are no recent sessions, but you can search for an older session." :
                                                        "No recent sessions match your filter criteria."
                                                }
                                            </Card.Description>
                                        </Card.Content>
                                    </Card> :
                                    <List>
                                        {
                                            searchResults.map(result =>
                                                <List.Item key={`${result.id}-list-item`}>
                                                    <List.Content>
                                                        <Card onClick={() => props.searchClicked(result.id)} fluid>
                                                            <Card.Content>
                                                                <Card.Description>
                                                                    <h5>{result.id}</h5>
                                                                    {result.comments}
                                                                </Card.Description>
                                                            </Card.Content>
                                                        </Card>
                                                    </List.Content>
                                                </List.Item>)
                                        }
                                    </List>
                            }
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
